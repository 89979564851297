import React, { useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ProjectStatsType } from '../../timesheet/timesheet-types';
import { useTimesheetListingDash } from '../contexts/TimesheetListingDashContext';
import { formatMoney } from '../../../../utils/formatMoney';
import HoursPerProject from './HoursPerProject';

import generalStyles from '../../../App.module.scss';
import { UserRole } from '../../users/user.role';
import { useUser } from '../../users/UserContext';

interface ProjectContributorsType {
  projectName: string;
  hours: number;
  cost?: number;
  projectContributors: number | undefined;
}

function ProjectContributorsTable(): React.ReactElement {
  const {
    user: { role },
  } = useUser();
  const { projectStats, loadingStats } = useTimesheetListingDash();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpand = (_: any, record: any): void => {
    setExpandedRowKeys((prevState) =>
      prevState.includes(record.projectName)
        ? prevState.filter((name) => name !== record.projectName)
        : [...prevState, record.projectName],
    );
  };
  function transformDataForTable(
    projectStats: ProjectStatsType | undefined,
  ): ProjectContributorsType[] {
    if (typeof projectStats !== 'undefined') {
      return Object.keys(projectStats).map((project) => ({
        key: project,
        projectName: project === 'allProjects' ? 'Total' : project,
        hours: projectStats[project].totalHours,
        cost: projectStats[project].totalCost,
        projectContributors: projectStats[project].nrContributors,
      }));
    } else {
      return [];
    }
  }

  const columns: ColumnsType<ProjectContributorsType> = [
    { title: 'Project', dataIndex: 'projectName', key: 'projectName' },
    { title: 'Contributors', dataIndex: 'projectContributors', key: 'projectContributors' },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
  ];
  if (role === UserRole.ADMIN) {
    columns.push({
      title: 'Cost (EUR)',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost) => formatMoney(cost),
    });
  }
  return (
    <Table
      columns={columns}
      dataSource={transformDataForTable(projectStats)}
      loading={loadingStats}
      bordered
      pagination={{
        pageSize: 20,
        total: typeof projectStats !== 'undefined' ? Object.keys(projectStats).length : 0,
        position: ['bottomCenter'],
      }}
      rowClassName={(record) =>
        expandedRowKeys.includes(record.projectName) ? generalStyles.selectedTableRow : ''
      }
      expandable={{
        expandedRowClassName: () => generalStyles.expandedRowContent,
        rowExpandable: (record) => record.projectName !== 'Total',
        expandRowByClick: true,
        expandedRowKeys,
        onExpand: handleExpand,
        expandedRowRender: (record) => {
          return projectStats !== undefined ? (
            <HoursPerProject {...projectStats[record.projectName]} />
          ) : (
            <p>Not enough data available</p>
          );
        },
      }}
    />
  );
}

export default ProjectContributorsTable;
