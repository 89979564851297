import React, { ReactNode } from 'react';
import { Table, Progress } from 'antd';
import { StatsPerProject } from '../../timesheet/timesheet-types';
import { ColumnsType } from 'antd/es/table';
import { useUser } from '../../users/UserContext';
import { UserRole } from '../../users/user.role';

interface ProjectProgressType {
  userName: string;
  progress: ReactNode;
  hours: number;
}

interface ProjectProgress extends StatsPerProject {}

const HoursPerProject: React.FC<ProjectProgress> = ({ totalHours, hoursPerUser }) => {
  const {
    user: { role },
  } = useUser();

  let data: ProjectProgressType[] = [];
  if (hoursPerUser !== undefined) {
    data = Object.entries(hoursPerUser).map(([userName, hours]) => ({
      key: userName,
      userName,
      progress: null,
      hours,
    }));
  }

  const columns: ColumnsType<ProjectProgressType> = [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
      render: (_: any, record: ProjectProgressType) => record.userName,
      width: '33.3%',
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',

      render: (_: any, record: ProjectProgressType) => {
        const percentage = (record.hours / totalHours) * 100;
        return <Progress percent={Math.round(percentage)} status='normal' />;
      },
      width: '33.3%',
    },
    {
      title: 'Hours Worked',
      dataIndex: 'hours',
      key: 'hours',
      render: (_: any, record: ProjectProgressType) => `${record.hours} hours`,
      width: '33.3%',
      align: role === UserRole.PM ? 'center' : 'left',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />;
};

export default HoursPerProject;
