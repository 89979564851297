import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  PaperClipOutlined,
  UserOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

import { useUser } from '../pages/users/UserContext';
import { UserRole } from '../pages/users/user.role';

type MenuItem = Required<MenuProps>['items'][number];
interface MenuItemConfig {
  title: string;
  route: string;
  component: React.ReactNode;
  roles: string[];
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return { key, icon, children, label };
}

const itemsConfig: MenuItemConfig[] = [
  {
    title: 'Dashboard',
    route: '/dashboard',
    component: <DashboardOutlined />,
    roles: [UserRole.ADMIN, UserRole.PM],
  },
  {
    title: 'Invoices',
    route: '/invoices',
    component: <ScheduleOutlined />,
    roles: [UserRole.ADMIN, UserRole.PM, UserRole.COLLABORATOR],
  },
  {
    title: 'Timesheet',
    route: '/timesheet',
    component: <PaperClipOutlined />,
    roles: [UserRole.ADMIN, UserRole.PM, UserRole.COLLABORATOR],
  },
  {
    title: 'Projects',
    route: '/projects',
    component: <ProjectOutlined />,
    roles: [UserRole.ADMIN],
  },
  { title: 'Users', route: '/users', component: <UserOutlined />, roles: [UserRole.ADMIN] },
];

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { role } = user;

  const items = useMemo(() => {
    return itemsConfig
      .filter((item: MenuItemConfig) => item.roles.includes(role))
      .map((item: MenuItemConfig) => getItem(item.title, item.route, item.component));
  }, [role]);

  return (
    <Menu
      theme='dark'
      defaultSelectedKeys={[window.location.pathname]}
      mode='inline'
      items={items}
      style={{
        padding: 10,
      }}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};

export default MainMenu;
